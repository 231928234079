<template>
  <div>
    <div class="main flex fd-c ai-c">
      <div class="gou"></div>
      <div class="sc">退款申请成功!</div>
      <div class="tj">您的退款申请已提交，感谢您的使用！</div>
      <div class="bt flex jc-c">
        <div class="sy"
             @click="$router.push('/home')">返回首页</div>
        <!-- <div class="sy">查看订单</div> -->
      </div>
    </div>
    <v-loveGoodsList />
  </div>
</template>
<script>
import loveGoodsList from '@/components/loveGoodsList'
export default {
  data() {
    return {

    }
  },
  components: {
    'v-loveGoodsList': loveGoodsList
  },
}
</script>

 <style scoped>
.main .sy {
  width: 1.94rem;
  height: 0.62rem;
  line-height: 0.62rem;
  text-align: center;
  font-size: 0.28rem;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 1rem;
  margin-top: 0.3rem;
}
.main .bt {
  width: 6rem;
}
.main .tj {
  font-size: 0.28rem;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
  margin-top: 0.1rem;
}
.main .sc {
  font-size: 0.42rem;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
  margin-top: 0.2rem;
}
.main .gou {
  width: 0.98rem;
  height: 1rem;
  background-image: url(/img/refule/gouxuan.png);
  background-repeat: no-repeat;
  background-size: auto 100%;
}
.main {
  height: 3.98rem;
  background: linear-gradient(90deg, #f37f3a 0%, #f35f3a 100%);
  padding-top: 0.41rem;
  padding-bottom: 0.2rem;
  box-sizing: border-box;
}
</style>
